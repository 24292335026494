import { graphql, useStaticQuery } from 'gatsby';
import { Branch, BranchSettings } from '@content/types/branch';
import * as branchesTransformers from '@content/transformers/BranchesTransformers';
import * as branchesListTransformers from '@content/transformers/BranchesListTransformers';

export interface BranchesDataProps {
  allBranches: Branch[];
  branchSettigns: BranchSettings;
}

const BranchesQuery = (): BranchesDataProps => {
  const branchesData = useStaticQuery(graphql`
    query {
      # api data
      allNodeBranches {
        edges {
          node {
            ...IposApiBranchesFragment
          }
        }
      }
      allSiteSettingEntityBranches {
        edges {
          node {
            langcode
            field_hero_image {
              alt
            }
            field_hero_headline {
              value
            }
            field_hero_button_text
            field_button_text_1
            field_button_text_2
            field_hero_text {
              value
            }
            field_text_1 {
              value
            }
            field_text_2 {
              value
            }
            field_text_3 {
              value
            }
            field_meta_description
            field_meta_title
            relationships {
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_hero_image {
                localFile {
                  childImageSharp {
                    fixed(width: 1600, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_hero_image_mobile {
                localFile {
                  childImageSharp {
                    fixed(width: 1600, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return {
    allBranches: branchesTransformers.transformBranches(branchesData.allNodeBranches),
    branchSettigns: branchesListTransformers.transformBranchesList(
      branchesData.allSiteSettingEntityBranches
    ),
  };
};

export default BranchesQuery;
