import React from 'react';
import styled from '@emotion/styled';

import { dimensions, colors, respondFrom, breakpoints, css, mixins, respondTo } from '@styles';

const StyledBadge = styled.span<StyledBadgeProps>`
  display: inline-block;
  text-transform: uppercase;
  font-size: ${dimensions.fontSize.smaller}px;
  color: ${colors.white};
  background-color: ${colors.blue.default};
  width: 200px;
  text-align: center;
  vertical-align: middle;
  padding: 2px 0 1px;
  font-weight: 500;

  position: absolute;
  top: 25px;
  right: ${props => (!props.leftOnMobile ? '-65px' : false)};
  left: ${props => (props.leftOnMobile ? '-65px' : false)};
  transform: ${props => (props.leftOnMobile ? 'rotate(-45deg)' : 'rotate(45deg)')};

  ${mixins.fontSmoothing}
  ${mixins.transitionDefault}

  ${props =>
    props.toLeft
      ? respondFrom(
          breakpoints.lg,
          css`
            left: -65px;
            right: auto;
            transform: rotate(-45deg);
          `
        )
      : respondFrom(
          breakpoints.lg,
          css`
            right: -65px;
            left: auto;
            transform: rotate(45deg);
          `
        )}

  ${props => props.order === 1 && css`
    top: 45px;
    right: ${(!props.leftOnMobile ? '-45px' : 'auto')};
    left: ${(props.leftOnMobile ? '-45px' : 'auto')};
    ${
      props.toLeft
        ? respondFrom(
            breakpoints.lg,
            css`
              left: -45px;
              right: auto;
            `
          )
        : respondFrom(
            breakpoints.lg,
            css`
              right: -45px;
              left: auto;
            `
          )
      }
  `}

  ${props =>
    props.hideOnMobile
      ? respondTo(
          breakpoints.lg,
          css`
            display: none;
          `
        )
      : null}

  ${props => props.color === "gray" && css`
      background-color: ${colors.grayDark};
  `}
`;

interface BadgeProps extends StyledBadgeProps {
  text?: string;
}

interface StyledBadgeProps {
  leftOnMobile?: boolean;
  toLeft?: boolean;
  hideOnMobile?: boolean;
  order?: 0 | 1;
  color?: 'blue' | 'gray';
}

export default ({ toLeft, leftOnMobile, hideOnMobile, text = 'Kasa online', order = 0, color = 'blue' }: BadgeProps) => {
  if(text.trim().length === 0) return null;

  return (
    <StyledBadge 
    leftOnMobile={leftOnMobile} 
    toLeft={toLeft} 
    hideOnMobile={hideOnMobile} 
    order={(text.length >= 12 && order === 0) ? 1 : order} 
    color={color}
  >
    {text}
  </StyledBadge>
  )
};
