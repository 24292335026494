import get from 'lodash.get';
import {
  Node__BranchesConnection,
  Node__BranchesEdge,
  Node__Branches,
  Paragraph__Branches_Check_What_S_Going_Onparagraph__Branches_Featuresparagraph__Branches_Heroparagraph__Branches_Recommendationparagraph__Branches_Recommended_Devicesparagraph__Branches_Recommended_Tariffsparagraph__Branches_Testimonialsparagraph__Branches_What_Is_Iposparagraph__Bullets_Containerparagraph__Discounts_ContainerUnion as ParagraphSectionType,
  Paragraph__Branches_Hero,
  Paragraph__Branches_What_Is_Ipos,
  File__File,
  Paragraph__Branches_Recommendation,
  Paragraph__Branches_Features,
  Paragraph__Bullets_Container,
  Paragraph__BulletsFilterInput,
  Paragraph__Branches_Recommended_Devices,
  Paragraph__Branches_Device,
  Paragraph__Profits,
  Paragraph__Branches_Recommended_Tariffs,
  Paragraph__Branches_Testimonials,
  Paragraph__Discounts_Container,
} from '../../graphql/types';
import { FileImageSharp } from '../types/api';
import {
  Branch,
  BranchSection,
  BranchSectionHeroProps,
  BranchSectionWhatisProps,
  BranchSectionRecommendationProps,
  BranchSectionFeaturesProps,
  BranchSectionBulletsProps,
  BranchSectionDevicesProps,
  BranchSectionTariffsProps,
  BranchSectionTestimonialsProps,
  BranchSectionDiscountsProps,
  BranchSectionSavingUpProps,
} from '@content/types/branch';
import languages from '@content/json/languages.json';

interface DiscountContainerSavingUp extends Paragraph__Discounts_Container {
  field_branches_text?: string;
}

export const transformBranches = (branches?: Node__BranchesConnection | null): Branch[] => {
  if (!branches) {
    return [];
  }

  const data = get(branches, 'edges', []).map(
    (edge: Node__BranchesEdge) => edge.node
  ) as Node__Branches[];

  const compare = (a, b) => {
    let compare;

    if (a.field_weight > b.field_weight) {
      compare = 1;
    } else if (a.field_weight < b.field_weight) {
      compare = -1;
    }
    return compare;
  };
  // let branchesLanguages;

  // branchesLanguages = languages.map(lang => {
  //   lang;
  // });

  const allBranches = data.sort(compare).map((branch: Node__Branches) => {
    const fileImage = get(branch.relationships, 'field_branches_image') as FileImageSharp;
    const fileImageLarge = get(branch.relationships, 'image_large') as FileImageSharp;

    return {
      seo: {
        title: get(branch, 'field_meta_title', ''),
        description: get(branch, 'field_meta_description', ''),
        image: get(branch, 'relationships.field_branches_image.localFile.publicURL'),
      },
      langcode: get(branch, 'langcode', ''),
      id: branch.id || '',
      name: branch.title || '',
      icon: get(branch, 'relationships.field_branches_icon.localFile.publicURL', ''),
      image: fileImage ? fileImage.localFile : null,
      image_large: fileImageLarge ? fileImageLarge.localFile : null,
      fromDate: branch.field_branches_date || '',
      hasOnline: branch.field_pl_is_online || false,
      slug: branch.path ? (branch.path.alias || branch.id).replace('/', '') : branch.id,
      sections: (get(branch.relationships, 'field_components', []) as ParagraphSectionType[])
        .map((section: ParagraphSectionType):
          | BranchSection
          | BranchSectionBulletsProps
          | undefined => {
          if (!section.internal) {
            return undefined;
          }
          const { type } = section.internal;
          const fileImage = get(section.relationships, 'field_branches_image') as FileImageSharp;
          const fileImageMobile = get(
            section.relationships,
            'field_branches_image_mobile'
          ) as FileImageSharp;

          if (type === 'paragraph__branches_hero') {
            const s = section as Paragraph__Branches_Hero;
            const fileDeviceImage = get(
              section.relationships,
              'field_branches_device_image'
            ) as FileImageSharp;

            return {
              type: 'hero',
              title: get(s.field_paragraph_headline, 'value', ''),
              paragraph: get(s.field_branches_text, 'value', ''),
              image: fileImage ? fileImage.localFile : null,
              imageMobile: fileImageMobile ? fileImageMobile.localFile : null,
              deviceImage: fileDeviceImage ? fileDeviceImage.localFile : null,
              buttonText: s.field_branches_button_text,
              buttonRoute: branch.path ? branch.path.alias || branch.id : '',
            } as BranchSectionHeroProps;
          } else if (type === 'paragraph__branches_what_is_ipos') {
            const s = section as Paragraph__Branches_What_Is_Ipos;

            const fileIcon1 = get(s.relationships, 'field_ico_1') as File__File;
            const fileIcon2 = get(s.relationships, 'field_ico_2') as File__File;
            const fileIcon3 = get(s.relationships, 'field_ico_3') as File__File;

            return {
              type: 'what_is_ipos',
              title: get(s.field_paragraph_headline, 'value', ''),
              paragraph: get(s.field_branches_text, 'value', ''),
              image: fileImage ? fileImage.localFile : null,
              icon1: fileIcon1
                ? { publicURL: get(fileIcon1.localFile, 'publicURL', '') }
                : undefined,
              icon2: fileIcon2
                ? { publicURL: get(fileIcon2.localFile, 'publicURL', '') }
                : undefined,
              icon3: fileIcon3
                ? { publicURL: get(fileIcon3.localFile, 'publicURL', '') }
                : undefined,
              text1: get(s.field_short_text_1, 'value', ''),
              text2: get(s.field_short_text_2, 'value', ''),
              text3: get(s.field_short_text_3, 'value', ''),
            } as BranchSectionWhatisProps;
          } else if (type === 'paragraph__branches_recommendation') {
            const s = section as Paragraph__Branches_Recommendation;

            return {
              type: 'recommendation',
              headline: get(s.field_paragraph_headline, 'value', ''),
              paragraph: get(s.field_branches_text, 'value', ''),
              image: fileImage ? fileImage.localFile : null,
              isOnline: s.field_is_online || false,
              buttonText: s.field_nc_button_text,
              buttonRoute: branch.path ? branch.path.alias || branch.id : '',
            } as BranchSectionRecommendationProps;
          } else if (
            type === 'paragraph__branches_features' ||
            type === 'paragraph__branches_check_what_s_going_on'
          ) {
            const s = section as Paragraph__Branches_Features;

            return {
              type: type === 'paragraph__branches_features' ? 'features' : 'features2',
              headline: get(s.field_paragraph_headline, 'value', ''),
              paragraph: get(s.field_branches_text, 'value', ''),
              image: fileImage ? fileImage.localFile : null,
              // buttonText: s.field_branches_button_text,
              // buttonRoute: branch.path ? branch.path.alias || branch.id : '',
              features: [...Array(4).keys()].map((e, i) => {
                const icon = get(s.relationships, `field_ico_${i + 1}.localFile.publicURL`);
                const text = get(s, `field_short_text_${i + 1}.value`);

                if (icon) {
                  return {
                    image: icon,
                    text: text || '',
                  };
                }
              }),
            } as BranchSectionFeaturesProps;
          } else if (type === 'paragraph__bullets_container') {
            const s = section as Paragraph__Bullets_Container;
            const items = get(
              s.relationships,
              `field_bullets_item`,
              []
            ) as Paragraph__BulletsFilterInput[];

            return {
              type: 'bullets',
              bullets: items.map(item => {
                const fileBulletImage = get(item.relationships, 'field_image') as FileImageSharp;
                return {
                  paragraph: get(item.field_text, 'value', ''),
                  video: item.field_video ? item.field_video : null,
                  image: fileBulletImage ? fileBulletImage.localFile : null,
                };
              }),
            } as BranchSectionBulletsProps;
          } else if (type === 'paragraph__branches_recommended_devices') {
            const s = section as Paragraph__Branches_Recommended_Devices;
            const items = get(
              s.relationships,
              `field_branches_devices`,
              []
            ) as Paragraph__Branches_Device[];

            return {
              type: 'products',
              headline: get(s.field_paragraph_headline, 'value', ''),
              branchSlug: branch.path ? branch.path.alias || branch.id : branch.id,
              products: items.map(item => {
                const imageSharp = get(
                  item.relationships,
                  'field_branches_image'
                ) as FileImageSharp;

                return {
                  id: item.drupal_id || '',
                  name: item.field_branches_name,
                  image: imageSharp ? imageSharp.localFile : null,
                  isRecommended: item.field_recommended || false,
                  buttonText: item.field_nc_button_text,
                  buttonTextRecommended: item.field_button_text_recommended,
                  profits: (get(
                    item.relationships,
                    'field_branches_profits',
                    []
                  ) as Paragraph__Profits[]).map(profit => {
                    return {
                      active: profit.field_profits_active || false,
                      name: (profit.field_profits_name && profit.field_profits_name.value) || '',
                    };
                  }),
                };
              }),
            } as BranchSectionDevicesProps;
          } else if (type === 'paragraph__branches_recommended_tariffs') {
            const s = section as Paragraph__Branches_Recommended_Tariffs;

            return {
              type: 'tariffs',
              title: get(s.field_paragraph_headline, 'value', ''),
              subtitle: get(s.field_branches_text, 'value', ''),
              buttonLabel: get(s, 'field_nc_button_text', ''),

              tariffs: get(s.relationships, 'field_branches_tarrifs', []).map(tariff => {
                return {
                  name: tariff.field_branches_name,
                  recommended: tariff.field_recommended,
                  price: parseFloat(get(tariff, 'relationships.field_date_from_api.iposfee')) +
                    parseFloat(get(tariff, 'relationships.field_date_from_api.field_pl_locationfee')),
                  profits: get(tariff.relationships, 'field_branches_profits', []).map(profit => {
                    return {
                      active: profit.field_profits_active,
                      name: get(profit.field_profits_name, 'value', ''),
                    };
                  }),
                };
              }),

              // TODO
              pricePeriod: 'zł miesięcznie',
            } as BranchSectionTariffsProps;
          } else if (type === 'paragraph__branches_testimonials') {
            const s = section as Paragraph__Branches_Testimonials;
            const imageSharp = get(s.relationships, 'field_branches_image') as FileImageSharp;

            return {
              type: 'testimonial',

              // headline: 'Bla bla', // TODO
              // headlineLogo: TODO
              image: imageSharp ? imageSharp.localFile : null,
              paragraph: get(s.field_nq_quotation, 'value', ''),
            } as BranchSectionTestimonialsProps;
          } else if (type === 'paragraph__discounts_container') {
            const s = section as Paragraph__Discounts_Container;

            return {
              type: 'discounts',
              title: get(s.field_paragraph_headline, 'value', ''),
              paragraph: get(s.field_text, 'value', ''),
              discounts: get(s.relationships, 'field_discounts_item', []).map(discount => {
                const imageSharp = get(
                  discount.relationships,
                  'field_branches_image'
                ) as FileImageSharp;

                return {
                  id: get(discount, 'id', ''),
                  paragraph: get(discount.field_branches_text, 'value', ''),
                  buttonLabel: get(discount, 'field_branches_button_text', ''),
                  image: imageSharp ? imageSharp.localFile : null,
                  imageAlt: get(discount.field_branches_image, 'alt', ''),
                };
              }),
            } as BranchSectionDiscountsProps;
          } else if (type === 'paragraph__branches_saving_up_block') {
            const s = section as DiscountContainerSavingUp;

            return {
              type: 'savingUp',
              title: get(s.field_paragraph_headline, 'value', ''),
              text: get(s.field_branches_text, 'value', ''),
              buttonText: get(s, 'field_branches_button_text', ''),
            } as BranchSectionSavingUpProps;
          }
        })
        .filter(section => typeof section !== typeof undefined) as BranchSection[],
    };
  });

  const branchesLanguages = {};

  languages.codes.map(lang => {
    branchesLanguages[lang] = allBranches.filter(branch => branch.langcode === lang);
  });

  return branchesLanguages;
};
