/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash.get';
import {
  QuerySiteSettingEntityBranchesArgs,
  Site_Setting_Entity__BranchesEdge,
  Site_Setting_Entity__BranchesGroupConnection,
} from '../../graphql/types';
import { FileImageSharp } from '../types/api';
import { BranchSettings } from '@content/types/branch';

export const transformBranchesList = (
  branch?: Site_Setting_Entity__BranchesGroupConnection | null
): BranchSettings | {} => {
  const branchesLanguages = {};

  if (!branch) {
    return {};
  }

  branch.edges.forEach(edge => {
    const data = edge.node;

    if (!data) {
      return {};
    }

    const heroImage = get(data, 'relationships.field_hero_image') as FileImageSharp;
    const heroImageMobile = get(data, 'relationships.field_hero_image_mobile') as FileImageSharp;

    branchesLanguages[data.langcode] = {
      hero: {
        heroTitle: get(data, 'field_hero_headline.value', ''),
        heroParagraph: get(data, 'field_hero_text.value', ''),
        heroButtonLabel: get(data, 'field_hero_button_text', ''),
        heroImage: heroImage ? heroImage.localFile : null,
        heroImageMobile: heroImageMobile ? heroImageMobile.localFile : null,
        heroImageAlt: get(data, 'field_hero_image.alt', ''),
      },
      sectionTitle: get(data, 'field_text_1.value', ''),
      tileSmallParagraph: get(data, 'field_text_2.value', ''),
      tileSmallButtonLabel: get(data, 'field_button_text_2', ''),
      tileLargeParagraph: get(data, 'field_text_3.value', ''),
      tileLargeButtonLabel: get(data, 'field_button_text_1', ''),
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
    };
  });

  return branchesLanguages;
};
